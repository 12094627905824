import {connect} from 'react-redux';
import XLSX from 'xlsx';

import env from 'env';
import {withApi} from 'components/API.jsx';
import OutstandingInvoices from './component.jsx';


const load = (API, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/outstanding-invoices`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'OUTSTANDING-INVOICES-SET-NOT-FOUND'
          });
          return null;
        }
        const {jobs} = resp;
        dispatch({
          type: 'OUTSTANDING-INVOICES-SET',
          jobs,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const save = (debtors, total, waiting, debtorIndex, locationIndex) => {
  const wb = XLSX.utils.book_new();
  debtors.forEach(({id, locations, total, waiting}) => {
    if (locations.length === 0) return;

    const debtor = debtorIndex[id].name;

    const rows = locations.reduce((acc, {id, buckets, total, waiting}) => {
      if (buckets.length === 0) return acc;

      const location = locationIndex[id].name;
      acc.push([location]);
      acc = buckets.reduce((acc, {title, jobs, total}) => {
        if (jobs.length === 0) return acc;
        acc.push(['', title]);
        acc = jobs.reduce((acc, {jobNumber, rego, claimNumber, makeModel, subtotal}) => {
          acc.push(['', '', jobNumber, rego, claimNumber, makeModel, subtotal / 100]);
          return acc;
        }, acc);

        return acc;
      }, acc);

      acc.push([]);
      acc.push([
        '',
        ...waiting.reduce((acc, w) => {
          acc.push(w.title);
          acc.push(w.total / 100);
          return acc;
        }, []),
        'Total',
        total / 100,
      ]);
      acc.push([]);
      acc.push([]);
      return acc;
    }, []);

    rows.push([
      ...waiting.reduce((acc, w) => {
        acc.push(w.title);
        acc.push(w.total / 100);
        return acc;
      }, []),
      'Total',
      total / 100,
    ]);

    const ws = XLSX.utils.aoa_to_sheet(rows);

    //// below are ensure the amount number field has the right format so that excel can sum it
    //const currencyFormat = '"$"#,##0.00_);\\("$"#,##0.00\\)';
    //jobs.forEach((e, i) => {
    //  ws[`I${i+2}`].z = currencyFormat
    //  ws[`J${i+2}`].z = currencyFormat
    //  ws[`K${i+2}`].z = currencyFormat
    //});

    XLSX.utils.book_append_sheet(wb, ws, debtor);
  });
  XLSX.writeFile(wb, 'outstanding-invoices.xlsx');
};

const toggle = (debtorId, locationId, sectionId) => ({
  type: 'OUTSTANDING-INVOICES-TOGGLE',
  debtorId,
  locationId,
  sectionId,
});

const mapStateToProps = (state, props) => {
  const {
    params: {
      debtors: {
        index: debtorIndex,
      },
      locations: {
        index: locationIndex,
      },
    },
    outstandingInvoices,
  } = state;
  return {
    ...outstandingInvoices,
    debtorIndex,
    locationIndex,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: () => dispatch(load(API)),
  onSave: save,
  onToggle: (debtorId, locationId, sectionId) => dispatch(toggle(debtorId, locationId, sectionId)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OutstandingInvoices)
);
